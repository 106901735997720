// jQuery start
jQuery(function () {

    // Smooth scroll
    $('a[href^="#"]').click(function(e) {
    var headerHight = 210;//ずれている位置を決める
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top-headerHight;

        $.when(
            $("html, body").animate({
            scrollTop: position
            }, 400, "swing"),
            e.preventDefault(),
        ).done(function() {
            var diff = target.offset().top;
            if (diff === position) {
            } else {
            $("html, body").animate({
                scrollTop: diff
            }, 10, "swing");
            }
        });
    });

    // ヘッダートグルメニュー
    $('#nav-toggle,#overlay').on('click', function() {
        $('body').toggleClass('open');
    });

    
    //テキストのカウントアップの設定
    var bar = new ProgressBar.Line(splash_text, {//id名を指定
        strokeWidth: 0,//進捗ゲージの太さ
        duration: 1000,//時間指定(1000＝1秒)
        trailWidth: 0,//線の太さ
        text: {//テキストの形状を直接指定	
            style: {//天地中央に配置
                // position:'absolute',
                left:'50%',
                top:'50%',
                padding:'0',
                margin:'0',
                // transform:'translate(-50%,-50%)',
                'font-size':'56px',
                'font-weight':'700',
                'line-height':'1',
                color:'#fffde9',
            },
            autoStyleContainer: false //自動付与のスタイルを切る
        },
        step: function(state, bar) {
            bar.setText(Math.round(bar.value() * 100) + '%'); //テキストの数値
        }
    });

    //ローディングアニメーション
    bar.animate(1.0, function () {//バーを描画する割合を指定します 1.0 なら100%まで描画します
        $("#splash").delay(500).animate({
            'marginLeft': '-100vw'
        });//アニメーションが終わったら#splashエリアをフェードアウト
    });  
    

    // ナビゲーション
    $(".openbtn").click(function () {//ボタンがクリックされたら
        $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
        $("#g-nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
        $(".circle-bg").toggleClass('circleactive');//丸背景にcircleactiveクラスを付与
    });
    
    $("#g-nav a").click(function () {//ナビゲーションのリンクがクリックされたら
        $(".openbtn").removeClass('active');//ボタンの activeクラスを除去し
        $("#g-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
        $(".circle-bg").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
    });

    // メイン画像のオプション
    $(".works-slider").slick({
        autoplay: false, // 自動再生ON
        arrows: true, // 矢印非表示
        arrows: true,
        prevArrow: '<button type="button" class="slide-arrow prev-arrow"><span class="slide-arrow__arrow prev-arrow__arrow"></span></button>',
        nextArrow: '<button type="button" class="slide-arrow next-arrow"><span class="slide-arrow__arrow next-arrow__arrow"></span></button>',
        dots: true,
        centerMode: true,
        centerPadding: "12%",
        infinite: false,
        responsive: [{
            breakpoint: 1280,
            settings: {
                centerPadding: "6%",
            }
        }]
    })

    $('.loop-slider').slick({
        autoplay: true, //自動でスクロール
        autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
        speed: 7000, //スライドが流れる速度を設定
        cssEase: "linear", //スライドの流れ方を等速に設定
        slidesToShow: 1, //表示するスライドの数
        swipe: false, // 操作による切り替えはさせない
        arrows: false, //矢印非表示
        pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
        pauseOnHover: false, //スライダーにマウスホバーした時にスライドを停止させるか
        lazyLoad: 'linear',
        useCSS: false,
    });

});
// jQuery End


// ディレイスクロールアニメ
$(window).on('load', function(){

    function delayScrollAnime() {
        var time = 0.3;//遅延時間を増やす秒数の値
        var value = time;
        $('.delayScroll').each(function () {
            var parent = this;					//親要素を取得
            var elemPos = $(this).offset().top;//要素の位置まで来たら
            var scroll = $(window).scrollTop();//スクロール値を取得
            var windowHeight = $(window).height();//画面の高さを取得
            var childs = $(this).children();	//子要素を取得
            
            if (scroll >= elemPos - windowHeight && !$(parent).hasClass("play")) {//指定領域内にスクロールが入ったらまた親要素にクラスplayがなければ
                $(childs).each(function () {
                    
                    if (!$(this).hasClass("fadeUp")) {//アニメーションのクラス名が指定されているかどうかをチェック
                        
                        $(parent).addClass("play");	//親要素にクラス名playを追加
                        $(this).css("animation-delay", value + "s");//アニメーション遅延のCSS animation-delayを追加し
                        $(this).addClass("fadeUp");//アニメーションのクラス名を追加
                        value = value + time;//delay時間を増加させる
                        
                        //全ての処理を終わったらplayを外す
                        var index = $(childs).index(this);
                        if((childs.length-1) == index){
                            $(parent).removeClass("play");
                        }
                    }
                })
            }else {
                $(childs).removeClass("fadeUp");//アニメーションのクラス名を削除
                value = time;//delay初期値の数値に戻す
            }
        })
    }

    delayScrollAnime();/* アニメーション用の関数を呼ぶ*/
});








